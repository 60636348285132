<template>
  <div>
    <en-table-layout
      :stripe="false"
      :tableData="tableData.data"
      :loading="loading"
    >
      <template slot="toolbar">
        <div class="col toolbar-title"></div>
        <el-button
          size="small"
          type="primary"
          @click="handleAddSiteMenu"
        >
          添加导航
        </el-button>
      </template>

      <template slot="table-columns">
        <el-table-column
          prop="navigation_name"
          label="排序"
        >
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="navigation_name"
          label="导航名称"
        />
        <el-table-column
          prop="url"
          label="URL"
        />
        <el-table-column
          label="操作"
          width="300"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleEditSiteMenu(scope.$index, scope.row)"
            >编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDeleteSiteMenu(scope.$index, scope.row)"
            >删除</el-button>
            <el-button
              size="mini"
              @click="handleSortSiteMenu(scope.$index, scope.row, 'up')"
            >上移</el-button>
            <el-button
              size="mini"
              @click="handleSortSiteMenu(scope.$index, scope.row, 'down')"
            >下移</el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-size="tableData.page_size"
        :total="tableData.data_total"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
      >
      </el-pagination>
    </en-table-layout>

    <el-dialog-x-dialog
      :title="(siteMenuForm.navigation_id ? '编辑' : '添加') + '导航菜单'"
      :visible.sync="dialogVisible"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="siteMenuForm"
        :rules="siteMenuRules"
        ref="siteMenuForm"
        label-width="110px"
      >
        <el-form-item
          label="导航名称"
          prop="navigation_name"
        >
          <el-input
            v-model="siteMenuForm.navigation_name"
            clearable
            :maxlength="4"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="是否显示"
          prop="isHidden"
        >
          <el-radio
            v-model="isHidden"
            label='1'
          >显示</el-radio>
          <el-radio
            v-model="isHidden"
            label='0'
          >不显示</el-radio>
        </el-form-item>
        <el-form-item
          label="排序"
          prop="index"
        >
          <el-input-number
            v-model="siteMenuForm.index"
            controls-position="right"
            :min="1"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="URL"
          prop="url"
        >
          <el-input
            v-model="siteMenuForm.url"
            clearable
            :disabled="siteMenuForm.navigation_id ? true : false"
            :maxlength="225"
          ></el-input>
        </el-form-item>
        <el-form-item style="text-align:right;">
          <el-button
            @click="dialogVisible = false"
            size="small"
          >取 消</el-button>
          <el-button
            type="primary"
            @click="submitSiteMenuForm('siteMenuForm')"
            size="small"
          >确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import * as API_SiteMenu from "@/api/siteMenu";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";

export default {
  name: "pcSiteMenu",
  components: { EnTableLayout },
  data() {
    return {
      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        client_type: "PC",
      },

      /** 列表数据 */
      tableData: {},

      /** 添加、编辑导航菜单 dialog */
      dialogVisible: false,
      isHidden: "1",

      /** 添加、编辑导航菜单 表单 */
      siteMenuForm: {},

      /** 添加、编辑导航菜单 表单规则 */
      siteMenuRules: {
        navigation_name: [this.MixinRequired("请输入导航菜单名称！")],
        index: [this.MixinRequired("请输入排序！")],
        url: [this.MixinRequired("请输入导航菜单链接！")],
      },
    };
  },
  mounted() {
    this.GET_SiteMenuList();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_SiteMenuList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_SiteMenuList();
    },

    /** 添加导航菜单 */
    handleAddSiteMenu() {
      this.siteMenuForm = {
        isHidden: 1,
        index: 1,
      };
      this.dialogVisible = true;
    },

    /** 编辑导航菜单 */
    handleEditSiteMenu(index, row) {
      console.log(row);
      this.siteMenuForm = this.MixinClone(row);
      this.siteMenuForm["index"] = index + 1;
      // this.siteMenuForm['isHidden'] = '0'
      if (row.is_show) {
        this.isHidden = String(row.is_show);
      } else {
        this.isHidden = "0";
      }
      this.dialogVisible = true;
    },

    /** 删除导航菜单 */
    handleDeleteSiteMenu(index, row) {
      this.$confirm("确定要删除这个导航菜单吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          API_SiteMenu.deleteSiteMenu(row.navigation_id).then((response) => {
            this.$message.success("删除成功！");
            this.GET_SiteMenuList();
          });
        })
        .catch(() => {});
    },

    /** 添加、编辑导航菜单 提交表单 */
    submitSiteMenuForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { navigation_id } = this.siteMenuForm;
          this.siteMenuForm["is_show"] = this.isHidden;
          if (navigation_id) {
            API_SiteMenu.editSiteMenu(navigation_id, this.siteMenuForm).then(
              (response) => {
                this.dialogVisible = false;
                this.$message.success("保存成功！");
                this.MixinSetTableData(
                  this.tableData,
                  "navigation_id",
                  navigation_id,
                  response
                );
              }
            );
          } else {
            API_SiteMenu.addSiteMenu(
              this.params.client_type,
              this.siteMenuForm
            ).then((response) => {
              this.dialogVisible = false;
              this.$message.success("添加成功！");
              this.GET_SiteMenuList();
            });
          }
        } else {
          this.$message.error("表单填写有误，请检查！");
          return false;
        }
      });
    },

    /** 导航排序 */
    handleSortSiteMenu(index, row, sort_type) {
      API_SiteMenu.sortSiteMenu(row.navigation_id, sort_type).then(
        (response) => {
          this.GET_SiteMenuList();
          this.$message.success("操作成功！");
        }
      );
    },

    /** 获取导航菜单列表 */
    GET_SiteMenuList() {
      this.loading = true;
      API_SiteMenu.getSiteMenuList(this.params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
